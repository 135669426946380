import { getDateTimeFromDto as getDate, getValueFromDto as getValue } from './_helpers.js';

export default class RentalItem {
	constructor(dto) {
		this.id = getValue(dto, 'id', 'number', 0);
		// this.number = getValue(dto, 'number', 'number', 0);
		this.name = getValue(dto, 'name', 'string', '');
		this.rentalItemTypeId = getValue(dto, 'rentalItemTypeId', 'number', null);
		this.rentalItemType = null;
		this.datePurchased = getDate(dto, 'datePurchased', null);
		this.dateRetired = getDate(dto, 'dateRetired', null);
		Object.defineProperty(this, 'rentalItemType', { enumerable: false });
	}

	isOrdered(now) { return now < this.datePurchased; }
	isRetired(now) { return this.dateRetired !== null && this.dateRetired <= now; }
	isActive(now) { return !this.isOrdered(now) && !this.isRetired(now); }
	isScheduled(scheduledIdsSet) { return scheduledIdsSet.has(this.id); }
}
