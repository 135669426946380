import { makeComparator, url as urlHelper } from '@/helpers/helpers';
import { getIdb, idbHelpers } from '@/idb';
import RentalItem from '@/models/RentalItem';
import { DateTime } from 'luxon';
import { fetchAllPages, fetchWrap, idbResponse, isIdbResponse, offlineResponse } from '../_helpers';
import cache from './cache';

const idbStore = 'rentalItems';

export default {
	/**
	 * Get all rental item
	 * @returns (async) Returns an array of rental item objects if the request was successful, otherwise a Response.
	 */
	async getAll() {
		const idb = await getIdb();
		let response, data = [], timestamp;
		let useIdb = await cache.isCacheHit(idbStore);
		if (!useIdb) {
			try {
				timestamp = DateTime.now();
				response = await fetchAllPages('/api/RentalItems', x => data.push(x));
			} catch {
				useIdb = true;
				response = offlineResponse();
			}
		}
		if (useIdb && idb) {
			data = await idb.getAll(idbStore);
			data.sort(makeComparator('name'));
			response = idbResponse(200);
		}
		if (response.ok) {
			if (idb && !isIdbResponse(response)) {
				await idbHelpers.replaceAll(idb, idbStore, data);
				await cache.setTimestamp(idbStore, timestamp);
			}
			return data.map(x => new RentalItem(x));
		} else {
			throw response;
		}
	},
	/**
	 * Get scheduled rental item IDs
	 * @returns {Promise<Array<number>>} (async) Returns an array of rental item IDs if the request was successful, otherwise throws a Response.
	 */
	async getScheduled() {
		let response;
		try {
			response = await fetchWrap('/api/RentalItems/Scheduled');
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			return await response.json();
		} else {
			throw response;
		}
	},
	/**
	 * Get rental items by ID
	 * @returns (async) Returns an array of rental item objects if the request was successful, otherwise a Response.
	 */
	async getByIds(ids) {
		const idb = await getIdb();
		let response, data = [], timestamp;
		let useIdb = await cache.isCacheHit(idbStore);
		if (!useIdb) {
			try {
				timestamp = DateTime.now();
				if (ids.length > 100) {
					ids = ids.slice(0, 100);
					console.warn('rental items getByIds was called with more than 100 ids');
				}
				const url = urlHelper('/api/RentalItems/ByIds', { ids: ids.join(',') });
				response = await fetchWrap(url);
				if (response.ok) { data = await response.json(); }
			} catch {
				useIdb = true;
				response = offlineResponse();
			}
		}
		if (useIdb && idb) {
			data = (await idb.getAll(idbStore)).filter(x => ids.includes(x.id)).sort(makeComparator('name'));
			response = idbResponse(200);
		}
		if (response.ok) {
			return data.map(x => new RentalItem(x));
		} else {
			throw response;
		}
	},
	/**
	 * Get a rental item
	 * @param {Number} id rental item ID
	 * @returns (async) Returns a rental item if the request was successful, otherwise a Response.
	 */
	async getById(id) {
		let response, data = null;
		let useIdb = await cache.isCacheHit(idbStore);
		if (!useIdb && await cache.canCache()) {
			// cache all for future re-use
			if ((data = (await this.getAll()).find(x => x.id === id) ?? null)) {
				return data;
			} else {
				throw idbResponse(404);
			}
		}
		if (!useIdb) {
			try {
				response = await fetchWrap('/api/RentalItems/' + id);
				if (response.ok) { data = await response.json(); }
			} catch {
				useIdb = true;
				response = offlineResponse();
			}
		}
		const idb = await getIdb();
		if (useIdb && idb) {
			data = await idb.get(idbStore, id);
			response = data ? idbResponse(200) : idbResponse(404);
		}
		if (response.ok) {
			if (idb && !isIdbResponse(response)) {
				await idb.put(idbStore, data);
			}
			return new RentalItem(data);
		} else {
			throw response;
		}
	},
	/**
	 * Create a rental item
	 * @param {RentalItem} model rental item to create.
	 * @returns (async) Returns the new RentalItem if the request was successful, otherwise a Response.
	 */
	async create(model) {
		let response;
		try {
			response = await fetchWrap('/api/RentalItems', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			await cache.clearTimestamp(idbStore);
			return new RentalItem(await response.json());
		} else {
			return response;
		}
	},
	/**
	 * Update a rental item
	 * @param {RentalItem} model rental item to update.
	 * @returns (async) Returns the updated rental item if the request was successful, otherwise a Response.
	 */
	async update(model) {
		let response;
		try {
			response = await fetchWrap('/api/RentalItems/' + model.id, {
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			await cache.clearTimestamp(idbStore);
			return new RentalItem(model);
		} else {
			return response;
		}
	},
	/**
	 * Delete a rental item
	 * @param {Number} id RentalItem ID to delete.
	 * @returns (async) Returns true if the request was successful (or not found), false if the rental item could not be deleted, otherwise a Response.
	 */
	async deleteById(id) {
		let response;
		try {
			response = await fetchWrap('/api/RentalItems/' + id, { method: 'DELETE' });
		} catch {
			return offlineResponse();
		}
		if (response.ok) {
			await cache.clearTimestamp(idbStore);
			return true;
		} else if (response.status === 404) {
			return true;
		} else if (response.status === 409) {
			return false;
		} else {
			return response;
		}
	}
};
